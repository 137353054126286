import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core';
import { Link, graphql } from 'gatsby';

import Bio from '../components/Bio';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import MaybeWrapper from '../components/MaybeWrapper';
import React from 'react';
import SEO from '../components/seo';
import { SubscriptionForm } from '../components/SubscriptionForm';

export const Post = ({
  title,
  slug,
  cover,
  coverPosition = 'top',
  excerpt,
  node = {
    fields: {
      releasedNotForced: true,
    },
    frontmatter: {},
  },
  disabled,
  divider = true,
  style = {
    marginBottom: '12px',
  },
}) => {
  const hasCover =
    !!cover && coverPosition === 'top' && !!cover.childImageSharp.fixed;
  const isReleased = node.fields.releasedNotForced === true;

  return (
    <div style={style}>
      <MaybeWrapper
        check={slug}
        wrap={({ children }) => (
          <Link style={{ boxShadow: `none` }} to={slug}>
            {children}
          </Link>
        )}
      >
        <Grid
          container
          style={{
            opacity: isReleased ? 1 : 0.4,
            paddingBottom: '20px',
            opacity: !!slug ? 1 : 0.5,
          }}
          spacing={8}
        >
          <Grid item xs={12} sm={hasCover ? 9 : 12}>
            <Typography variant={'h6'} color={'textPrimary'}>
              {title}
            </Typography>
            {!!excerpt ? (
              <Typography
                variant={'body2'}
                color={'textSecondary'}
                gutterBottom
              >
                {excerpt}
              </Typography>
            ) : null}
            {isReleased ? null : (
              <Chip variant={'outlined'} color={'secondary'} label={'draft'} />
            )}
          </Grid>
          {hasCover ? (
            <Hidden xsDown>
              <Grid item sm={3}>
                <Img
                  style={{
                    maxWidth: '100%',
                    height: '60px',
                  }}
                  fixed={cover.childImageSharp.fixed}
                />
              </Grid>
            </Hidden>
          ) : null}
        </Grid>
        {divider ? <Divider /> : null}
      </MaybeWrapper>
    </div>
  );
};
