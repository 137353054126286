import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { graphql } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/Layout'
import { Post } from '../components/Post'
import SEO from '../components/seo'
import { SubscriptionForm } from '../components/SubscriptionForm'

const Previews = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      const { title } = node.frontmatter
      const { slug } = node.fields || {}
      return (
        <Typography key={title} gutterBottom color={'textSecondary'}>
          {title}
        </Typography>
      )
    })}
  </>
)
const Posts = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      return (
        <Post
          key={node.id}
          coverPosition={'top'}
          slug={node.fields.slug}
          title={node.frontmatter.title || node.fields.slug}
          cover={node.frontmatter.cover}
        />
      )
    })}
  </>
)

const mapArrToPosts = arr =>
  arr.map(title => ({
    node: {
      frontmatter: {
        title,
      },
    },
  }))

const Section = ({ header, points }) => (
  <>
    <Typography variant={'overline'} color={'textSecondary'}>
      {header}
    </Typography>
    <List>
      {points.map(point => (
        <ListItem
          key={point}
          divider={points.indexOf(point) !== points.length - 1}
        >
          <ListItemText primary={point} />
        </ListItem>
      ))}
    </List>
  </>
)

export default props => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
      allMdx: { edges },
    },
    location,
  } = props

  const [showDescription, setShowDescription] = useState(false)

  return (
    <>
      <Layout location={location} title={title}>
        <SEO
          title={'10x Better Gatsby'}
          keywords={[`gatsby`, `programming`, `tuning`]}
        />
        <Typography variant={'h6'} color={'secondary'}>
          SERIES
        </Typography>
        <Typography variant={'h3'} color={'secondary'}>
          10x Better Gatsby
        </Typography>

        <Typography variant={'subtitle1'} color={'secondary'}>
          Notes How To Extract All Juice From Great Gatsby
        </Typography>
        <br />
        <br />
        <img
          style={{ width: '100%' }}
          src={'https://media.giphy.com/media/5IMcyqKbkg0O4/giphy.gif'}
        />
        <br />
        <br />
        <Typography variant={'overline'}>POSTS</Typography>
        <Posts
          posts={edges.filter(post => {
            return (
              post.node.frontmatter.projects &&
              post.node.frontmatter.projects.includes('gatsby')
            )
          })}
        />
        <Typography variant={'overline'}>SUBSCRIBE</Typography>
        <SubscriptionForm />
        <br />

        <br />
        <Typography variant={'overline'}>COMING LATER</Typography>
        <Previews
          posts={mapArrToPosts([
            'How To Add Covers To a Gatsby Blog Like A Pro',
            'How To Set Up a Newsletter',
            'MDX — Does It Worth It?',
            'How to Use Absolute Path in Gatsby Code',
            'Make Gatsby friends with Twitter',
            '...',
          ])}
        />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { released: { eq: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
            released
            releasedNotForced
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            projects
            cover {
              publicURL
              childImageSharp {
                fixed(width: 240, height: 120) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
