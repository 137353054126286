import { StaticQuery, graphql } from 'gatsby';

import Image from 'gatsby-image';
import React from 'react';
import { Typography } from '@material-ui/core';

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={(data) => {
        const { author, social } = data.site.siteMetadata;
        return (
          <div
            style={{
              maxWidth: '600px',
              display: `flex`,
            }}
          >
            <div style={{ textAlign: 'center', marginRight: '16px' }}>
              <Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author}
                style={{
                  marginBottom: 0,
                  minWidth: 50,
                  borderRadius: `100%`,
                }}
              />
              <br />
              <strong>{author}</strong>
            </div>
            <div>
              <Typography component={'p'}>
                Enterpreneur. Dancer. Programmer. Creator. Building tools.
              </Typography>
            </div>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`;

export default Bio;
